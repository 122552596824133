import { Link } from "react-router-dom";
import Post from "../post/Post";

import "./posts.css";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Posts() {

  const [mydata, setMyData] = useState([]);

  useEffect(() => {
    axios.get('https://nodejsapi.wowdevqa.com/blogs')
      .then(res => {
        if (res.data.Status === "Success") {
          console.log(res.data)
          setMyData(res.data.results);
        } else {
          console.log("Error")
        }
      })
      .catch(err => console.log(err));
  }, []);
  return (
    <div className="posts">
      {mydata.map((blog, id) => (
        <div className="post">

          <img src={`https://nodejsapi.wowdevqa.com/images/` + blog.image} className="postImg" alt={blog.title} />
          <div className="postInfo">
            <div className="postCats">
              <span className="postCat">
                <Link to={`/post/` + blog.category} className='btn btn-primary btn-sm me-2'>{blog.category}</Link>
              </span>
            </div>
            <span className="postTitle">
              <Link to={`/post/` + blog.title} className='btn btn-primary btn-sm me-2'>{blog.title}</Link>
            </span>
            <hr />
            <span className="postDate">{blog.created_at}</span>
          </div>
          <div className="postdiv">
            <p className="postDesc"

              dangerouslySetInnerHTML={{
                __html: blog.sub_title
              }}>
            </p>
          </div>
        </div>
      ))
      }
    </div>
  );
}
