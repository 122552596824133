import { Link } from "react-router-dom";
import "./sidebar.css";
import logo from './logo.jpg'
import { useEffect, useState } from "react";
import axios from "axios";
import { Facebook, Instagram, Pinterest, Twitter } from "@material-ui/icons";
export default function Sidebar() {
const [categories,setCategories]=useState([]);

 useEffect(()=>{
  fetchCategories();
 })
 const fetchCategories = async () => {
  try {
      const response = await axios.get('https://nodejsapi.wowdevqa.com/categories');
      setCategories(response.data);
  } catch (error) {
      console.error('Error fetching categories: ', error);
  }
  // console.log(categories)
};

  return (
    <div className="sidebar">
      <div className="sidebarItem">
        <span className="sidebarTitle">ABOUT ME</span>
        <img
        src={logo}
        alt=""
        />
        <p style={{fontSize:"9px"}}>
        Digital:Marketing: Software: Development: Testing: WowDevQA:AI: ArtificialIntelligence: ML: MachineLearning: DL: DeepLearningInformationSecurity: CyberSecurity: SecurityTesting: Hacking: QA: QualityAssurance:Top: 15: Software: 1. Online Voting Polling Events Competition 2. Personal Financing Credit Score Builder Analyzer 3. Multi-Website Advertisement Agent 4. File Security Multi-Level Authentication 5. News Agency Distributor 6. Global Dental 7. Hotels Resorts HomeStays Reservation 8. Restaurants Ordering Serving Delivery 9. Travels Tours Hiking Trekking Mountaineering 10. Lightweight Healthcare 11. Cyber Security Law rules policy 12. Affordable Education for All 13. Stock Trading Prediction Analytics 14. Ecommerce 15. AI ML DL ArtificialIntelligence MachineLearning DeepLearning

        </p>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">CATEGORIES</span>
        <ul className="sidebarList">
          {categories.map((cat)=>(
             <li className="sidebarListItem">
             <Link className="link" to="/posts?cat=Life">
               {cat.category}
             </Link>
           </li>
          ))}
  
        </ul>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">FOLLOW US</span>
        <div className="sidebarSocial">
          <Facebook/>
          <Instagram/>
          <Pinterest/>
          <Twitter/>
        </div>
      </div>
    </div>
  );
}
