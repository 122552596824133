import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Topbar from '../topbar/Topbar';
import Header from '../header/headerpub';
import { Link, useNavigate, useParams } from 'react-router-dom';

function Search() {
  const {q}=useParams();
  const [searchResults, setSearchResults] = useState([]);
  const navigate=useNavigate()
  useEffect(() => {
    axios.get("https://nodejsapi.wowdevqa.com/search?q=" + q, {
    }).then(res => {
      if (res.data.Status === "Success") {
        setSearchResults(res.data.results);
        navigate("")
      } else {
        console.log("Error")
      }
    })
      .catch(err => console.log(err));
  },[]);

  return (
    <div>
      <Topbar />
      <Header />
      <div className="home">
        <div className="posts">
          {searchResults.map((blog, id) => (
            <div className="post">
              <img src={`https://nodejsapi.wowdevqa.com/images/` + blog.image} className="postImg" alt={blog.title} />
              <div className="postInfo">
                <div className="postCats">
                  <span className="postCat">
                    <Link to={`/post/` + blog.category} className='btn btn-primary btn-sm me-2'>{blog.category}</Link>
                  </span>
                </div>
                <span className="postTitle">
                  <Link to={`/post/` + blog.id} className='btn btn-primary btn-sm me-2'>{blog.title}</Link>
                </span>
                <hr />
                <span>{blog.created_at}</span>

              </div>
              <p className="postDesc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: blog.sub_title
                  }}></div>
              </p>
            </div>
          ))
          }
        </div>
      </div>
    </div>
  );
}
export default Search;
