import React, { useEffect, useState } from 'react';
import { Table, TableRow, TableHead, TableBody, TableCell, TableContainer, Paper, Button } from "@material-ui/core";
import { Modal } from '@material-ui/core';
import { Box } from '@material-ui/core';
import {  CircularProgress, TextField } from "@material-ui/core";
// styles
import useStyles from "../../styles"
import axios from "axios";
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Header from '../../../../components/Header/Header';
import { Link} from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function MyCategory() {
   
    var classes = useStyles();
    var [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState('');

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://nodejsapi.wowdevqa.com/categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories: ', error);
        }
        console.log(categories)
    };

    const createCategory = async () => {
        try {
            await axios.post('https://nodejsapi.wowdevqa.com/categories/create', { category: categoryName });
            fetchCategories();
            setCategoryName('');
            window.location.href = '/admin/categories';
        } catch (error) {
            console.error('Error creating category: ', error);
        }
    };
    const deleteCategory = async (id) => {
        try {
            await axios.get(`https://nodejsapi.wowdevqa.com/categories/delete/` + id,);
            fetchCategories();
        } catch (error) {
            console.error('Error deleting category: ', error);
        }
    };
    return (
        <>
            <Header />
            <Sidebar /><br/>
            <br/>
            <div className={classes.dashboardcontent}>
            <div style={{ textAlign: "right", marginTop:"2rem",alignItems: "right", display: "flex",justifyContent:"right"  }}>
                <Button variant="contained" onClick={handleOpen}>Create New Category</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <TextField
                            id="email"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={categoryName}
                            onChange={e => setCategoryName(e.target.value)}
                            margin="normal"
                            placeholder="New Category"
                            type="text"
                            fullWidth/>
                        <div className={classes.formButtons}>
                            {isLoading ? (
                                <CircularProgress size={12} className={classes.loginLoader} />
                            ) : (
                                <Button
                                disabled={
                                  categoryName.length === 0 
                                }
                                onClick= {createCategory}
                                variant="contained"
                                color="primary"
                                size="large" >
                                Create
                              </Button>
                            )}
                        </div>
                    </Box>
                </Modal>

            </div>
            <br/>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">Categories</TableCell>
                            <TableCell align="right">Action&nbsp;(g)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category) => (
                            <TableRow
                                key={category.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="category">
                                    {category.id}
                                </TableCell>
                                <TableCell align="right">{category.category}</TableCell>
                                <TableCell align="right">
                                    <Button variant="contained" onClick={() => deleteCategory(category.id)}>Delete</Button>
                                   
                                    <Link to={`/admin/categories/edit/`+category.id} variant="contained" ><Button variant="contained">Update</Button></Link>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
           
            <br />
            <br />
            <br />
            </div>
        </>
    );
}