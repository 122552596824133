import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {useNavigate } from 'react-router-dom';
import Header from '../../../../components/Header/Header';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import useStyles from '../../styles';
import { Button, TextField } from '@material-ui/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        //[{ 'font': [] }],
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image"],
        [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"],
    ],
};

const formats = [
  "header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "align", "color", "background",
];
function CreateBlog() {
    var classes = useStyles();
    const [categories, setCategories] = useState([]);

    const [data, setData] = useState({
        title: "",
        sub_title: "",
        image: "",
        category: ""
    });
    const navigate = useNavigate()
    useEffect(() => {
        axios.get("https://nodejsapi.wowdevqa.com/categories").then(res => {
            setCategories(res.data);
            console.log(res.data)
        }).catch((err) => {
            console.log(err)
        })

    }, []);
   
    const handleSubmit = (event) => {
        event.preventDefault();
        const formdata = new FormData();
        formdata.append("title", data.title);
        formdata.append("sub_title", data.sub_title);
        formdata.append("image", data.image);
        formdata.append("category", data.category);
        axios.post('https://nodejsapi.wowdevqa.com/blog/create', formdata)
            .then(res => {
                navigate('/admin/blogs')
            })
            .catch(err => console.log(err));
    }
    const handleChangeEditor = (content, delta, source, editor) => {
        console.log(editor.getHTML());
        setData({ ...data, sub_title: editor.getHTML() });
    };

    return (
        <>
            <Header />
            <Sidebar /><br />

            <div className={classes.dashboardcontent}>
                <div style={{ justifyContent: "center", textAlign: "center" }}>
                    <h2>Create Category</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <TextField
                                id="email"
                                InputProps={{
                                    classes: {
                                        underline: classes.textFieldUnderline,
                                        input: classes.textField,
                                    },
                                }}
                                placeholder='Enter title' autoComplete='off'
                                onChange={e => setData({ ...data, title: e.target.value })} value={data.title} margin="normal" type="text" /><br />
                            <br />
                            <select onChange={e => setData({ ...data, category: e.target.value })}>

                                <option>Please choose one option</option>
                                {categories.map((option, index) => (
                                     <option key={index} >
                                        {option.category}
                                    </option>))
                                }
                            </select>
                            <br />
                            <ReactQuill type="text"
                                style={{ height: "200px" }}
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                onChange={handleChangeEditor}
                                value={data.sub_title}
                                placeholder="Contents" />
                            <br />

                            <TextField
                                id="email"
                                InputProps={{
                                    classes: {
                                        underline: classes.textFieldUnderline,
                                        input: classes.textField,
                                    },
                                }}
                                onChange={e => setData({ ...data, image: e.target.files[0] })}
                                margin="normal"
                                placeholder="Upload file"
                                type="file" />
                        </div>
                        <div>
                            <Button type="submit" variant="contained" color="primary" size="large" >Create Blog</Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreateBlog
