import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useStyles from "../../styles";
import Header from '../../../../components/Header/Header';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import { Button, Input, TextField } from '@material-ui/core';



function UpdateCategory() {
    const [data, setData] = useState({

        category: '', })
    const [msg,setMsg]=useState("");
    const navigate = useNavigate();
    var classes = useStyles();


    const { id } = useParams();

    useEffect(() => {
        axios.get('https://nodejsapi.wowdevqa.com/categories/edit/' + id)
            .then(res => {
                // console.log(res.data.result[0])
                setData({
                    ...data, category: res.data.result[0].category,
                })
                setMsg(res.data.Status)
            })
            .catch(err => console.log(err));
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://nodejsapi.wowdevqa.com/categories/update/' + id, data)
            .then(res => {
                if (res.data.Status === "Success") {
                    navigate('/admin/categories')
                }
            })
            .catch(err => console.log(err));
    }
    return (
        <>
            <Header />
            <Sidebar /><br />
            <div className={classes.dashboardcontent}>
                <div style={{justifyContent:"center", textAlign:"center"}}>
                     
                    <h2>Update Category</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                        <TextField
                                id="email"
                                InputProps={{
                                    classes: {
                                        underline: classes.textFieldUnderline,
                                        input: classes.textField,}, }}
                                onChange={e => setData({ ...data, category: e.target.value })}
                                value={data.category} 
                                margin="normal"
                                placeholder="Title"
                                type="text"/>
                        </div>
                        <div>
                            <Button type="submit" variant="contained" color="primary" size="large" >Update</Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateCategory;
