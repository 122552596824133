import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useNavigate } from "react-router-dom";



export default function Header(props) {
  var classes = useStyles();
const navigate=useNavigate();
  // useEffect(() => {
  //   // Get the login status from session storage
  //   const login = sessionStorage.getItem("login");

  //   if (login === "admin_login") {
  //     // Redirect to login page
  //    navigate("admin/login")
  //   } else {
  //     // Redirect to 404 page
  //     navigate("*")
  //   }
  // }, []);

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  var [isSearchOpen, setSearchOpen] = useState(false);

  const logout = () => {
    sessionStorage.clear();
    window.location.href = '/admin/login';
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          DashBoard
        </Typography>
        <div className={classes.grow} />
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >

          </div>

        </div>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={logout}
        >
          <AccountIcon classes={{ root: classes.signOut }} />
         
        </IconButton>
        <p>Logout</p>
      </Toolbar>
    </AppBar>
  );
}
