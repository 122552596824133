import { Link, Navigate, useNavigate } from "react-router-dom";
import "./topbar.css";
import {Facebook, Instagram, Pinterest, Twitter} from "@material-ui/icons";
import { useState } from "react";

export default function Topbar() {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    var searchvalue = "/search/" + searchTerm;
    navigate( searchvalue);
  };
  const user = true;
  return (
    <div className="top">
      <div className="topLeft">
        <Facebook />
        <Instagram />
        <Pinterest />
        <Twitter />
      </div>
      <div className="topCenter">
        <ul className="topList">
          <li className="topListItem">
            <Link className="link" to="/">
              HOME
            </Link>
          </li>
          <li className="topListItem"><Link className="link" to="/about">
            ABOUT
          </Link></li>
        </ul>
      </div>
      <div className="topRight">

        <div>
          <form onSubmit={handleSubmit}>
            <input type="text" value={searchTerm} onChange={handleChange} />
            <button type="submit">Search</button>
          </form>
          <ul>
            {searchResults.map(result => (
              <li key={result.id}>
                {result.title}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
